export const tabularData = {
	kkr: {
		m: 14,
		w: 9,
		l: 3,
		nr: 2,
		p: 20,
		nrr: 1.428,
		runs_scored: 2389,
		overs_faced: 225.0,
		runs_conceded: 2135,
		overs_bowled: 232.2,
	},
	srh: {
		m: 14,
		w: 8,
		l: 5,
		nr: 1,
		p: 17,
		nrr: 0.414,
		runs_scored: 2605,
		overs_faced: 247.0,
		runs_conceded: 2599,
		overs_bowled: 256.3,
	},
	rr: {
		m: 14,
		w: 8,
		l: 5,
		nr: 1,
		p: 17,
		nrr: 0.273,
		runs_scored: 2334,
		overs_faced: 252.1,
		runs_conceded: 2310,
		overs_bowled: 257.1,
	},
	rcb: {
		m: 14,
		w: 7,
		l: 7,
		nr: 0,
		p: 14,
		nrr: 0.459,
		runs_scored: 2758,
		overs_faced: 269.0,
		runs_conceded: 2646,
		overs_bowled: 270.1,
	},
	csk: {
		m: 14,
		w: 7,
		l: 7,
		nr: 0,
		p: 14,
		nrr: 0.392,
		runs_scored: 2524,
		overs_faced: 274.4,
		runs_conceded: 2415,
		overs_bowled: 274.3,
	},
	dc: {
		m: 14,
		w: 7,
		l: 7,
		nr: 0,
		p: 14,
		nrr: -0.377,
		runs_scored: 2573,
		overs_faced: 267.0,
		runs_conceded: 2762,
		overs_bowled: 275.5,
	},
	lsg: {
		m: 14,
		w: 7,
		l: 7,
		nr: 0,
		p: 14,
		nrr: -0.667,
		runs_scored: 2483,
		overs_faced: 277.5,
		runs_conceded: 2521,
		overs_bowled: 262.3,
	},
	gt: {
		m: 14,
		w: 5,
		l: 7,
		nr: 2,
		p: 12,
		nrr: -1.063,
		runs_scored: 2040,
		overs_faced: 238.2,
		runs_conceded: 2101,
		overs_bowled: 218.2,
	},
	pbks: {
		m: 14,
		w: 5,
		l: 9,
		nr: 0,
		p: 10,
		nrr: -0.353,
		runs_scored: 2487,
		overs_faced: 274.3,
		runs_conceded: 2612,
		overs_bowled: 277.3,
	},
	mi: {
		m: 14,
		w: 4,
		l: 10,
		nr: 0,
		p: 8,
		nrr: -0.318,
		runs_scored: 2568,
		overs_faced: 268.5,
		runs_conceded: 2660,
		overs_bowled: 269.3,
	},
};

export const matchData = {};
